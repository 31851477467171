import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useContext, useEffect, useState } from "react";
import { api } from "../../api/api.js";
import { AuthContext } from "../../context/AuthContext";

const FeaturedItems = ({ entity }) => {
    const [featuredThings, setFeaturedThings] = useState([]);
    const [loading, setLoading] = useState(false);

    //get all the tasks
    const retrieveAllFeatured = async () => {
        try {
            const res = await api.get(`/api/${entity}/featured`, { 'axios-retry': { retries: 5 } });
            return res.data;
        } catch (error) {
            console.error("Error fetching featured items: ", error);
            return null;
        }
    };

    useEffect(() => {
        try {
            setLoading(true);
            const getAllFeatured = async () => {
                const allFeatured = await retrieveAllFeatured();
                if (allFeatured) {
                    let cardItems = allFeatured.map((item) => <Card key={item._id} {...item} />);
                    setFeaturedThings(cardItems);
                }
            };
            getAllFeatured();
            setLoading(false);
        } catch (err) {
        }
    }, []);

    const Card = (item) => {
        return (
            <div className="col-auto px-3">
                <div className="card shadow h-100" >
                    <img src={item.img} className="card-img-top" alt="..."></img>
                    <div className="card-body fw-bold fs-4 mx-auto  text-center">{item.headline}</div>
                </div >
            </div>
        );
    };

    return (
        <>
            {!loading ?
                <Carousel
                    responsive={responsive}
                    autoPlay={false}
                    autoPlaySpeed={20000}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    infinite={true}
                    partialVisible={false}
                    focusOnSelect={true}
                    renderDotsOutside={false}
                    centerMode={false}
                    rtl={true}
                    customTransition={"transform 700ms ease-in-out"}
                    dotListClass="custom-dot-list-style"
                >
                    {featuredThings}
                </Carousel>
                :
                <>
                    <div className="row d-flex justify-content-center align-items-center p-3 m-0">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </>
            }
        </>
    );
};
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1600 },
        items: 4,
        partialVisibilityGutter: 10
    },
    tablet: {
        breakpoint: { max: 1600, min: 1000 },
        items: 2,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 1000, min: 0 },
        items: 1,
        partialVisibilityGutter: 20
    }
};

export default FeaturedItems;
