import React from "react";
import TaskSummaryChart from './TaskSummaryChart.jsx';

const TaskStatusWidget = ({ taskChartData, totalTasks }) => {
    const content = totalTasks === 0 ? (
        <div className="text-center p-3">
            <p>No Tasks yet! Create some</p>
        </div>
    ) : (
        <TaskSummaryChart taskChartData={taskChartData} totalTasks={totalTasks} />
    );

    return (
        <div className="col-md-4 col-sm-12 mb-3">
            <div className="shadow h-100 d-flex flex-column">
                <div className="p-2 fw-bold primary-color">
                    Tasks
                </div>
                <div className="flex-grow-1">
                    {content}
                </div>
                <div className="p-2 text-muted primary-color">
                    {totalTasks} Tasks
                </div>
            </div>
        </div>
    );
};

export default TaskStatusWidget;
