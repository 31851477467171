import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";

const ChallengesModal = ({
    show,
    onClose,
    identifyChallenges,
    goalChallenges,
    goal,
    loading,
    setLoading,
}) => {

    const renderContent = (data, loadingState, placeholder) => {
        if (loadingState) {
            return (
                <div className="d-flex justify-content-center align-items-center py-4">
                    <Spinner animation="border" role="status" variant="secondary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            );
        }
        if (!data || data.length === 0) {
            return <div className="text-muted">{placeholder}</div>;
        }
        return <div>{data}</div>;
    };

    return (
        <Modal show={show} onHide={onClose} size="xl" className="rounded">
            <Modal.Header closeButton className="primary-color text-secondary rounded-top">
                <Modal.Title>Understand Challenges</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <div className="row mb-4">
                    <div className="col">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h5 className="card-title">Goal</h5>
                                {goal ? (
                                    <p className="card-text">{goal}</p>
                                ) : (
                                    <div className="alert alert-warning mb-0">
                                        <p className="mb-0">No goal set. Please go back and refine your goal before identifying challenges.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card shadow-sm">
                            <div className="card-header primary-color text-secondary">
                                <h5 className="card-title mb-0">Challenges:</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        {loading ? (
                                            <div className="d-flex justify-content-center align-items-center py-4">
                                                <Spinner animation="border" role="status" variant="secondary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        ) : (
                                            <>
                                                {goalChallenges.feedback && (
                                                    <div className="mb-4">
                                                        <p>{goalChallenges.feedback}</p>
                                                    </div>
                                                )}
                                                {goalChallenges.challenges && (
                                                    <div>
                                                        <h6 className="mb-3">Likely Challenges:</h6>
                                                        {goalChallenges.challenges.map((challenge, index) => (
                                                            <div key={index} className="p-2 border-bottom">
                                                                {challenge}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                <button
                                    className="btn btn-outline-secondary btn-sm me-2"
                                    onClick={() => identifyChallenges()}
                                    disabled={loading || !goal}
                                >
                                    <FontAwesomeIcon icon={faRedo} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn fw-bold text-secondary primary-color border border-secondary rounded-pill px-4"
                    onClick={() => identifyChallenges()}
                    disabled={loading || !goal}
                >
                    {loading ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                            Identifying Challenges...
                        </>
                    ) : (
                        'Identify Challenges'
                    )}
                </button>
                <button type="button" className="btn fw-bold text-white bg-secondary rounded-pill px-4" onClick={onClose}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChallengesModal;
