import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";

const PlanModal = ({
    show,
    onClose,
    executePlanAI,
    goal,
    milestones,
    setMilestones,
    habits,
    sethabits,
    tasks,
    setTasks,
    loading,
    setLoading,
}) => {
    const [loadingMilestones, setLoadingMilestones] = useState(false);
    const [loadingHabits, setLoadingHabits] = useState(false);
    const [loadingTasks, setLoadingTasks] = useState(false);

    const handleExecutePlanAI = async (type) => {
        if (type === 'plan') {
            setLoadingMilestones(true);
            setLoadingHabits(true);
            setLoadingTasks(true);
            setLoading(true);
        } else if (type === 'milestones') {
            setLoadingMilestones(true);
        } else if (type === 'habits') {
            setLoadingHabits(true);
        } else if (type === 'tasks') {
            setLoadingTasks(true);
        }

        try {
            await executePlanAI(type);
        } finally {
            if (type === 'plan') {
                setLoadingMilestones(false);
                setLoadingHabits(false);
                setLoadingTasks(false);
                setLoading(false);
            } else if (type === 'milestones') {
                setLoadingMilestones(false);
            } else if (type === 'habits') {
                setLoadingHabits(false);
            } else if (type === 'tasks') {
                setLoadingTasks(false);
            }
        }
    };

    const renderContent = (content, isLoading) => {
        if (isLoading) {
            return (
                <div className="d-flex justify-content-center align-items-center py-4">
                    <Spinner animation="border" role="status" variant="secondary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            );
        }
        if (!content || content.length === 0) {
            return <div className="text-muted">No content available yet.</div>;
        }
        return <div>{content}</div>;
    };

    return (
        <Modal show={show} onHide={onClose} size="xl" className="rounded">
            <Modal.Header closeButton className="primary-color text-secondary rounded-top">
                <Modal.Title>Plan Your Goal</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <div className="row mb-4">
                    <div className="col">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h5 className="card-title">Goal</h5>
                                {goal ? (
                                    <p className="card-text">{goal}</p>
                                ) : (
                                    <div className="alert alert-warning mb-0">
                                        <p className="mb-0">No goal set. Please go back and refine your goal before creating a plan.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-1 g-3">
                    {/* Milestones Card */}
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="card shadow-sm h-100">
                            <div className="card-header primary-color text-secondary">
                                <h5 className="card-title mb-0">Milestones:</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        {renderContent(milestones, loadingMilestones)}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                <button
                                    className="btn btn-outline-secondary btn-sm me-2"
                                    onClick={() => handleExecutePlanAI("milestones")}
                                    disabled={loading || !goal}
                                >
                                    <FontAwesomeIcon icon={faRedo} />
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Habits Card */}
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="card shadow-sm h-100">
                            <div className="card-header primary-color text-secondary">
                                <h5 className="card-title mb-0">Habits:</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        {renderContent(habits, loadingHabits)}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                <button
                                    className="btn btn-outline-secondary btn-sm me-2"
                                    onClick={() => handleExecutePlanAI("habits")}
                                    disabled={loading || !goal}
                                >
                                    <FontAwesomeIcon icon={faRedo} />
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Tasks Card */}
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="card shadow-sm h-100">
                            <div className="card-header primary-color text-secondary">
                                <h5 className="card-title mb-0">Tasks:</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        {renderContent(tasks, loadingTasks)}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                <button
                                    className="btn btn-outline-secondary btn-sm me-2"
                                    onClick={() => handleExecutePlanAI("tasks")}
                                    disabled={loading || !goal}
                                >
                                    <FontAwesomeIcon icon={faRedo} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn fw-bold text-secondary primary-color border border-secondary rounded-pill px-4"
                    onClick={() => handleExecutePlanAI('plan')}
                    disabled={loading || !goal}
                >
                    {loading ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                            Creating Plan...
                        </>
                    ) : (
                        'Create Plan'
                    )}
                </button>
                <button type="button" className="btn fw-bold text-white bg-secondary rounded-pill px-4" onClick={onClose}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default PlanModal;
