import { useContext, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Helmet } from "react-helmet";
import Header from "../components/page/Header";
import Navbar from "../components/page/Navbar";
import SideBar from "../components/page/SideBar";
import { api } from "../api/api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../context/AuthContext";
const stripePromise = loadStripe('pk_test_51NfMm2Cwvcp7G6axIcvTtQlKUVL7yw8djLMSu5S9pHCLHS9V3I3vJvYztmXKrLgckrWGcY43yBLB6f7cyX8Jp0vZ00uOAfx5KV');

const Plans = () => {
    const { user, dispatch } = useContext(AuthContext);
    const [subscription, setSubscription] = useState(user.goalsLevel);
    const [stripeCustomerId, setStripeCustomerId] = useState(user.stripeCustomerId);

    useEffect(() => {
        setSubscription(user.goalsLevel);
    }, [user.goalsLevel]);

    const cancelUnlimited = async (subscriptionId) => {
        try {
            setSubscription("free");

            const res = await api.post(`/api/stripe/cancelunlimited`, {
                userId: user._id,
                subscription: user.subscription,
            });

            dispatch({ type: "LOGIN_PASS", payload: res.data });
        } catch (error) {
            console.error('Error canceling subscription:', error);
        }
    };

    const makeUnlimited = async (priceId) => {
        try {
            const res = await api.post(`/api/stripe/createcheckout`, {
                stripeCustomerId: stripeCustomerId,
                priceId: priceId
            });

            const stripe = await stripePromise;

            const { error } = await stripe.redirectToCheckout({ sessionId: res.data.id });

            if (error) {
                console.error('Error:', error);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <Helmet>
                    <title>Empowering Goals & Habits Management - Choose Your Membership Level</title>
                    <meta name="description" content="Join Goals & Habits to register and embark on a journey towards a more fulfilling life. Select your membership level, set empowering goals, and cultivate productive habits. Get started now!" />
                </Helmet>
                <div className="row bg-white sticky-top">
                    <div className="col border-bottom border-dark">
                        <Navbar />
                    </div>
                </div>
                <div className="row flex-nowrap">
                    <div className="col-auto px-0">
                        <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'} border-end`}>
                            <SideBar />
                        </div>
                    </div>
                    <div className="col flex-grow-1">
                        <div className="row">
                            <div className="col">
                                <Header />
                            </div>
                        </div>
                        <div className="row py-3">
                            <div className="col-auto my-auto">
                                <div href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn border border-dark rounded-3 p-2">
                                    Side Menu
                                </div>
                            </div>
                            <div className="col d-flex align-items-center">
                                <h3 className="mb-0">Choose the best plan for you</h3>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-5">
                            <div className="row row-cols-1 row-cols-md-2">
                                <div className="col d-flex justify-content-cente h-100">
                                    <div className="card rounded-3 shadow-sm h-100">
                                        <div className="card-header py-2">
                                            <h4 className="my-0 fw-normal">Free</h4>
                                        </div>
                                        <div className="card-body">
                                            <h1 className="card-title pricing-card-title">$0<small className="text-muted fw-light">/mo</small></h1>
                                            <ul className="list-unstyled mt-3 mb-4">
                                                <li>Track 5 goals</li>
                                                <li>Track 5 habits</li>
                                                <li>Track 10 tasks</li>
                                                <li>Monitor one week trends</li>
                                            </ul>
                                            {user && subscription === "free" && (
                                                <div className="d-flex justify-content-center">
                                                    <button
                                                        type="button"
                                                        className="w-75 btn p-1 m-0 btn-secondary"
                                                    >
                                                        Current Plan
                                                    </button>
                                                </div>
                                            )}
                                            {user && subscription !== "free" && (
                                                <div className="d-flex justify-content-center">
                                                    <button
                                                        type="button"
                                                        id="free"
                                                        onClick={cancelUnlimited}
                                                        className="w-75 btn p-1 m-0 secondary-btn"
                                                    >
                                                        Switch to Free
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col d-flex justify-content-cente h-100">
                                    <div className="card rounded-3 shadow-sm h-100">
                                        <div className="card-header py-2">
                                            <h4 className="my-0 fw-normal">Unlimited</h4>
                                        </div>
                                        <div className="card-body">
                                            <h1 className="card-title pricing-card-title">$2.99<small className="text-muted fw-light">/mo</small></h1>
                                            <ul className="list-unstyled mt-3 mb-4">
                                                <li>Track unlimited goals</li>
                                                <li>Track unlimited habits</li>
                                                <li>Track unlimited tasks</li>
                                                <li>Monitor all trends</li>
                                            </ul>
                                            {user && subscription === "unlimited" && (
                                                <div className="text-primary d-flex justify-content-center">
                                                    <button
                                                        type="button"
                                                        className="w-75 btn p-1 m-0 btn-secondary"
                                                    >
                                                        Current Plan
                                                    </button>
                                                </div>
                                            )}
                                            {user && subscription !== "unlimited" && (
                                                <div className="d-flex justify-content-center">
                                                    <button
                                                        type="button"
                                                        id="unlimited"
                                                        onClick={() => makeUnlimited('price_1NkSCuCwvcp7G6axFrdJCAMK')}
                                                        className="w-75 btn p-1 m-0 secondary-btn"
                                                    >
                                                        Switch to Unlimited!
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    </div>
                </div>
            </div>
        </div>
    );
};

export default Plans;
