import '../../../src/styles/styles.css'; // The path should be relative to the current file

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { api } from "../../api/api.js";
import { useState, memo } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';


const SearchItem = ({
    item,
    linked,
    user,
    entity,
    loading,
    handleAdd,
    toastMessage,
    toastIcon
}) => {
    const [userItem, setUserItem] = useState(linked);
    const showToast = (message) => {
        toast(<CustomToastContent message={message} />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            progressClassName: 'custom-toast-progress'
        });
    };
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const CustomToastContent = ({ message }) => (
        <div className="custom-toast-content">
            <FontAwesomeIcon icon={faCheck} className="custom-toast-check" />
            <p>{message}</p>
        </div>
    );

    const SearchItemImage = memo(function SearchItemImage({ src }) {
        return <img src={src} decoding="sync" className="img-thumbnail" alt="" />;
    });

    return (
        <div className="col-md-4 col-12-sm py-2">
            <div className="card shadow m-1 p-0 h-100">
                <div className="card-body d-flex flex-column justify-content-between">
                    <SearchItemImage key={item.img} src={item.img} />
                    <div>
                        <p><strong>{item.headline}</strong></p>
                        <div className="d-flex align-items-center">
                            <div className="badge rounded-pill my-auto text-bg-secondary me-1">{item.category}</div>
                            {!userItem && (
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon icon={faCheck} className="text-success" style={{ backgroundColor: '#FFFFFF', borderRadius: '50%', padding: '5px' }} />
                                </div>
                            )}
                        </div>
                        <br />
                        <p className="">{item.description}</p>
                    </div>
                    <div className="text-center">
                        {user ? (
                            <button
                                className="btn secondary-btn p-1 w-50"
                                disabled={loading}
                                onClick={() => {
                                    handleAdd(item._id);
                                    showToast(toastMessage); // Call showToast here
                                }}
                            >
                                <p className="fw-bold my-auto">
                                    {userItem ? `Add ${capitalizeFirstLetter(entity)}` : "Add Again"}
                                </p>
                            </button>
                        ) : (
                            <div className="text-center">
                                <Link to="/login" className="btn secondary-btn p-1 m-0 w-50">
                                    Login to add {capitalizeFirstLetter(entity)}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>

    );
};
export default SearchItem;
