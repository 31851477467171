import '../../src/styles/styles.css'; // The path should be relative to the current file

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import SideBar from "../components/page/SideBar";

import { daysBetween, startOfDate, todayStart } from "../utils/theDateHelper.js";

import { api } from "../api/api.js";

import GoalWidget from "../components/workspace/GoalWidget";
import GoalStatusWidget from "../components/workspace/GoalStatusWidget";
import HabitStatusWidget from "../components/workspace/HabitStatusWidget";
import TaskStatusWidget from "../components/workspace/TaskStatusWidget";

import HabitWidget from "../components/workspace/HabitWidget";
import TaskWidget from "../components/workspace/TaskWidget.jsx";

const Workspace = () => {
    const [authorized, setAuthorized] = useState(true);

    function handleAuthorized(boolValue) {
        setAuthorized(boolValue);
        if (!boolValue) {
            dispatch({ type: "LOGOUT" });
        }
    };

    const { dispatch } = useContext(AuthContext);
    const { user } = useContext(AuthContext);

    const [goals, setGoals] = useState([]);
    const [totalGoals, setTotalGoals] = useState(0);
    const [goalStats, setGoalStats] = useState({});

    const [habits, setHabits] = useState([]);
    const [totalHabits, setTotalHabits] = useState(0);
    const [habitStats, setHabitStats] = useState({});

    const [tasks, setTasks] = useState([]);
    const [activeTasks, setActiveTasks] = useState([]);
    const [totalTasks, setTotalTasks] = useState(0);
    const [totalActiveTasks, setTotalActiveTasks] = useState(0);
    const [taskChartData, setTaskChartData] = useState([]);

    useEffect(() => {
        retrieveGoals();
        retrieveHabits();
        retrieveTasks();
    }, []);

    //Goal methods
    //******************************************************************************************* */
    //******************************************************************************************* */

    useEffect(() => {
        console.log("in workspace:useEffect: setGoalStats");
        // console.log("in workspace:goals.length:", goals.length);

        setTotalGoals(goals.length);

        if (goals.length > 0) {
            setGoalStats(calculateGoalStats());
        }
    }, [goals]);

    const retrieveGoals = async () => {
        console.log("in workspace: retrieveGoals");

        try {
            console.log("Making API request to retrieve goals for user ID:", user._id);
            const response = await api.get(`/api/user/populategoals/${user._id}`);
            console.log("API response received:");
            console.log("Status code:", response.status);
            console.log("Response data:", JSON.stringify(response.data));
            setGoals(response.data);
            setTotalGoals(response.data.length);
            console.log("Goals retrieved successfully. Total goals:", response.data.length);
        } catch (error) {
            console.log("Error in retrieveGoals:");
            console.log("Error message:", error.message);
            console.log("Error stack:", error.stack);
            handleAuthorized(false);
        } finally {
            console.log("retrieveGoals function completed.");
        }
    };
    const updateGoalOrder = async (reorderedGoals) => {
        console.log("in workspace: updateGoalOrder");
        setGoals(reorderedGoals);
        await api.put("/api/user/updategoalorder", { goals: reorderedGoals });

    }
    const calculateGoalStats = async () => {
        try {
            console.log("in workspace: calculateGoalStats");
            const total = goals.length;
            // console.log("total: " + goals.length);
            // console.log("Goals are: " + JSON.stringify(goals));

            const statusCounts = await goals.reduce((acc, { status }) => {
                // console.log("LOOK HERE");
                // console.log("user2goal: " + JSON.stringify(status));
                // console.log("user2goal.status: " + status);
                acc[status] = (acc[status] || 0) + 1;
                return acc;
            }, {});

            // console.log("workspace:statusCounts: " + JSON.stringify(statusCounts));

            setGoalStats({
                notStarted: statusCounts['Not Started'] || 0,
                offTrack: statusCounts['Off-Track'] || 0,
                onTrack: statusCounts['On-Track'] || 0,
                complete: statusCounts['Complete'] || 0,
                notStartedPercent: ((statusCounts['Not Started'] || 0) / total) * 100,
                offTrackPercent: ((statusCounts['Off-Track'] || 0) / total) * 100,
                onTrackPercent: ((statusCounts['On-Track'] || 0) / total) * 100,
                completePercent: ((statusCounts['Complete'] || 0) / total) * 100
            })
            // console.log("Look here - GoalStats: " + JSON.stringify(goalStats));
        }
        catch (error) {
            console.log("Error in calculateGoalStats: " + error);
        }
    };
    const removeGoal = async (goalId) => {
        try {
            await api.delete(`/api/user2goal/${goalId}`);
            setGoals(goals.filter(goal => goal._id !== goalId));
        } catch (error) {
            console.error(error);
        }
    }; const toggleGoalStatus = async (user2goalID, newGoalStatus) => {
        console.log("in workspace: toggleGoalStatus");
        // console.log("workspace:goalID: " + user2goalID);
        // console.log("workspace:newGoalStatus: " + newGoalStatus);

        setGoals(prevGoals => prevGoals.map(goal => {
            if (goal._id === user2goalID) {
                goal.status = newGoalStatus;
            }
            return goal;
        }));

        try {
            await api.put(`/api/user2goal/${user2goalID}`, { status: newGoalStatus });
        } catch (err) {
            console.error(err);
        }
    };
    const updateGoalDetail = async (user2goalID, detail) => {
        console.log("in workspace: updateGoalDetail");
        // console.log("workspace:goalID: " + user2goalID);
        // console.log("workspace:field: " + detail);
        // console.log("workspace:value: " + detail);

        try {
            await api.put(`/api/user2goal/${user2goalID}`, { detail });
            // Update local state if needed
        } catch (error) {
            console.error('Error updating habit detail: ', error);
        }
    };
    const updateGoalMeasure = async (user2goalID, measure) => {
        try {
            await api.put(`/api/user2goal/${user2goalID}`, { measure });
            // Update local state if needed
        } catch (error) {
            console.error('Error updating habit detail: ', error);
        }
    };
    const updateGoalDeadline = async (user2goalID, deadline) => {
        try {
            await api.put(`/api/user2goal/${user2goalID}`, { deadline });
            // Update local state if needed
        } catch (error) {
            console.error('Error updating habit detail: ', error);
        }
    };

    //Habit methods
    //******************************************************************************************* */
    //******************************************************************************************* */

    useEffect(() => {
        console.log("in workspace:useEffect: setHabitStats");
        if (habits.length > 0) {
            setHabitStats(calculateHabitsStats());
        }
    }, [habits]);

    const retrieveHabits = async () => {
        console.log("in workspace: retrieveHabits");

        try {
            const response = await api.get(`/api/user/populatehabits/${user._id}`);
            setHabits(response.data);
            setTotalHabits(response.data.length);
        } catch (error) {
            handleAuthorized(false);
            console.log("Error in retrieveHabits: " + error);
        } finally {

        }
    };
    const updateHabitOrder = async (reorderedHabits) => {
        console.log("in workspace: updateHabitOrder");
        setHabits(reorderedHabits);
        try {
            const response = await api.put("/api/user/updateHabitorder", { habits: reorderedHabits }, { timeout: 5000 });
            console.log("in workspace: updateHabitOrder: successfully updated habit order");
        } catch (error) {
            console.error('Error updating habit order: ', error);
        }
    }
    const calculateHabitsStats = () => {
        console.log("in workspace: CalculateHabitsStats");
        try {
            if (habits.length > 0) {
                const habitStats = habits.reduce((stats, habit) => {
                    const habitDates = habit.completionHistory.map(history => new Date(history.date).toISOString());
                    const uniqueDates = [...new Set(habitDates)].sort((a, b) => new Date(a) - new Date(b));

                    uniqueDates.forEach(date => {
                        const completed = habit.completionHistory.find(history => new Date(history.date).toISOString() === date).completed;
                        const existingStat = stats.find(stat => stat.itemDate === date);
                        if (existingStat) {
                            existingStat.Complete += completed ? 1 : 0;
                        } else {
                            stats.push({
                                dateFormatted: new Date(date).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' }),
                                Complete: completed ? 1 : 0,
                                itemDate: date
                            });
                        }
                    });

                    return stats;
                }, []);

                const returnedHabitStats = habitStats.sort((a, b) => new Date(a.itemDate) - new Date(b.itemDate));
                console.log("in workspace: calculateHabitsStats: habitStats: " + JSON.stringify(returnedHabitStats));
                return returnedHabitStats;
            }
        } catch (error) {
            console.log("Error in calculateHabitsStats: " + error);
        }
    }
    const calculateHabitStats = () => {
        console.log("in workspace: calculateHabitStats");
        const initialStats = { today: 0, week: 0, month: 0, year: 0, inception: 0 };

        if (!habits || habits.length === 0) {
            return initialStats;
        }

        const updateStats = (stats, dateDiff) => {
            if (dateDiff === 0) stats.today++;
            if (dateDiff <= 7) stats.week++;
            if (dateDiff <= 30) stats.month++;
            if (dateDiff <= 365) stats.year++;
            stats.inception++;
        };

        return habits.reduce((acc, habit) => {
            // console.log("workspace: habit: " + JSON.stringify(habit));
            habit.completionHistory.forEach(({ date, completed }) => {
                if (completed) {
                    const dateDiff = daysBetween(new Date(todayStart()), new Date(date));
                    updateStats(acc, dateDiff);
                }
            });

            return acc;
        }, { ...initialStats });
    };
    const incrementStats = (stats, dateDiff) => {
        if (dateDiff <= 6) stats.week++;
        if (dateDiff <= 27) stats.month++;
        if (dateDiff <= 364) stats.year++;
        stats.inception++;
    };
    const toggleHabitComplete = async (user2habitID, habitDay, newCompleteState) => {
        console.log("in workspace: toggleHabitComplete");
        // console.log("workspace:user2habitID: " + user2habitID);
        // console.log("workspace:habitDay: " + formatToISOString(habitDay));
        // console.log("workspace:newCompleteState: " + newCompleteState);

        try {
            // console.log("workspace:toggleHabitComplete: habits: " + JSON.stringify(habits));

            // Update the state of habits
            setHabits(prevHabits => {
                return prevHabits.map(habit => {
                    if (habit._id === user2habitID) {
                        const startOfDay = startOfDate(habitDay);

                        const existingCompletion = habit.completionHistory.find(
                            history => new Date(history.date).toISOString().split('T')[0] === startOfDay.toISOString().split('T')[0]
                        );

                        const updatedCompletionHistory = existingCompletion
                            ? habit.completionHistory.map(history =>
                                history.date === existingCompletion.date
                                    ? { ...history, completed: newCompleteState }
                                    : history)
                            : [...habit.completionHistory, { date: startOfDay.toISOString(), completed: newCompleteState }];

                        // Create a new habit object with updated completion history
                        const updatedHabit = { ...habit, completionHistory: updatedCompletionHistory };
                        return updatedHabit; // Return the new habit object
                    }
                    return habit; // Return the original habit if not the one being updated
                });
            });

            // Make an API call to update the habit completion on the server
            await api.post("/api/user2habit/handlehabit", {
                id: user2habitID,
                completed: newCompleteState,
                habitDay: new Date(habitDay).toISOString()
            });
            console.log("in workspace:handleComplete");

        } catch (error) {
            console.error('Error updating habit completion: ', error);
            // Handle errors, such as unauthorized access
        }
    };
    const toggleHabitFocus = async (user2habitID, newFocusState) => {
        console.log("in workspace: toggleHabitFocus");
        // console.log("user2habitID: " + user2habitID);
        // console.log("newFocusState: " + newFocusState);

        try {
            setHabits(prevHabits => prevHabits.map(habit => {
                if (habit._id === user2habitID) {
                    habit.focus = newFocusState;
                }
                return habit;
            }));
            await api.put(`/api/user2habit/${user2habitID}`, { focus: newFocusState });
        } catch (error) {
            console.error('Error updating habit completion: ', error);
            // Handle errors, such as unauthorized access
        }
    };
    const toggleHabitFrog = async (user2habitID, newFrogState) => {
        console.log("in workspace: toggleHabitFrog");
        // console.log("user2habitID: " + user2habitID);
        try {
            setHabits(prevHabits => prevHabits.map(habit => {
                if (habit._id === user2habitID) {
                    habit.frog = newFrogState;
                }
                return habit;
            }));
            await api.put(`/api/user2habit/${user2habitID}`, { frog: newFrogState });
        } catch (error) {
            console.error('Error updating habit completion: ', error);
            // Handle errors, such as unauthorized access
        }
    };
    const removeHabit = async (user2habitID) => {
        console.log("in workspace: removeHabit");
        try {
            await api.delete(`/api/user2habit/${user2habitID}`);
            setHabits(habits.filter(habit => habit._id !== user2habitID));
        } catch (error) {
            console.error(error);
        }
    };
    const updateHabitDetail = async (user2HabitID, detail) => {
        console.log("in workspace: updateHabitDetail");
        try {
            await api.put(`/api/user2habit/${user2HabitID}`, { detail });
            // Update local state if needed
        } catch (error) {
            console.error('Error updating habit detail: ', error);
        }
    };
    const updateHabitEnd = async (user2HabitID, end) => {
        console.log("in workspace: updateHabitEnd");
        try {
            await api.put(`/api/user2habit/${user2HabitID}`, { end });
            // Update local state if needed
        } catch (error) {
            console.error('Error updating habit end time: ', error);
        }
    };
    const updateHabitStart = async (user2HabitID, start) => {
        console.log("in workspace: updateHabitStart");
        try {
            await api.put(`/api/user2habit/${user2HabitID}`, { start });

        } catch (error) {
            console.error('Error updating habit start time: ', error);
            // Handle errors, such as unauthorized access
        }
    };
    const updateHabitStats = (habitId, newCompleteState) => {
    };


    //Task methods
    //******************************************************************************************* */
    //******************************************************************************************* */

    const addTask = async (taskText) => {
        console.log("in workspace: addTask");
        try {
            const res = await api.post(`/api/task/create`, { text: taskText, user: user._id });
            const newTask = res.data;
            updateTaskStates([...tasks, newTask]);
        } catch (err) {
            console.error(err);
        }
    };

    const createTaskChartData = () => {
        console.log("in workspace: createTaskChartData");

        if (!tasks || tasks.length === 0) return [];

        const filtered = tasks
            .filter(task => task.complete && task.completedDate)
            .map(task => {
                const itemDate = new Date(task.completedDate);
                return {
                    dateFormatted: `${itemDate.getMonth() + 1}/${itemDate.getDate()}`,
                    Complete: 1,
                    itemDate
                };
            });

        // console.log("in workspace: createTaskChartData: filtered: " + JSON.stringify(filtered));


        const aggregatedData = filtered.reduce((acc, item) => {
            const existingItem = acc.find(i => i.dateFormatted === item.dateFormatted);
            if (existingItem) {
                existingItem.Complete += item.Complete;
            } else {
                acc.push(item);
            }
            return acc;
        }, []);

        const tempData = aggregatedData.sort((a, b) => a.itemDate - b.itemDate);
        // console.log("in workspace: createTaskChartData: tempData: " + JSON.stringify(tempData));

        setTaskChartData(tempData);
    };


    const deleteTask = async (id) => {
        console.log("in workspace: deleteTask");

        try {
            await api.delete(`/api/task/${id}`);
            const updatedTasks = tasks.filter((item) => item._id !== id);
            updateTaskStates(updatedTasks);
        } catch (err) {
            console.error('Error deleting Task:', err);
        }
    };

    const retrieveTasks = async () => {
        try {
            console.log("in workspace: retrieveTasks");
            const response = await api.get(`/api/user/populatetasks/${user._id}`);
            const tasks = response.data;
            updateTaskStates(tasks);
        } catch (error) {
            handleAuthorized(false);
            console.log("Error in retrieveTasks: " + error);
        }
    };

    const toggleTaskComplete = async (taskID, newCompleteState) => {
        console.log("in workspace: toggleTaskComplete");
        // Update the local state of tasks
        const updatedTasks = tasks.map(task =>
            task._id === taskID ?
                {
                    ...task,
                    complete: newCompleteState,
                    completedDate: newCompleteState ? todayStart() : task.completedDate
                }
                : task
        );

        updateTaskStates(updatedTasks);

        // Update the task in the database
        try {
            const fieldsToUpdate = { complete: newCompleteState };
            if (newCompleteState) {
                fieldsToUpdate.completedDate = todayStart();
            }
            await updateTask(taskID, fieldsToUpdate);
        } catch (error) {
            console.error('Error updating task complete: ', error);
        }
    };

    const toggleTaskFocus = async (taskID, newFocusState) => {
        console.log("in workspace: toggleTaskFocus");

        // Update the local state of tasks
        const updatedTasks = tasks.map(task =>
            task._id === taskID ? { ...task, focus: newFocusState } : task
        );

        updateTaskStates(updatedTasks);

        try {
            await updateTask(taskID, { focus: newFocusState }); // Pass the focus state correctly
        } catch (error) {
            console.error('Error updating task focus: ', error);
        }
    };

    const toggleTaskFrog = async (taskID, newFrogState) => {
        console.log("in workspace: toggleTaskFrog");

        const updatedTasks = tasks.map(task =>
            task._id === taskID ? { ...task, frog: newFrogState } : task
        );

        updateTaskStates(updatedTasks);

        try {
            await updateTask(taskID, { frog: newFrogState });
        } catch (error) {
            console.error('Error updating task frog: ', error);
        }
    };

    const updateTask = async (taskID, fieldsToUpdate) => {
        console.log("in workspace: updateTask");
        console.log("in workspace: updateTask: taskID: " + taskID);
        console.log("in workspace: updateTask: fieldsToUpdate: " + JSON.stringify(fieldsToUpdate));
        try {
            await api.put(`/api/task/${taskID}`, fieldsToUpdate); // Use fieldsToUpdate directly
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };

    const updateTaskOrder = async (reorderedTasks) => {
        console.log("in workspace: updateTaskOrder");
        try {
            setTasks(reorderedTasks);
            updateTaskStates(reorderedTasks); // Update local state
            await api.put("/api/user/updatetaskorder", { tasks: reorderedTasks });
            console.log("in workspace: completed updating to-do order.");
        } catch (error) {
            console.error("Error updating to-do order:", error);
        }
    };

    const updateTaskStates = (tasks) => {
        setTasks(tasks);
        const activeTasks = tasks.filter(task => !task.archived);
        setActiveTasks(activeTasks);
        setTotalTasks(tasks.length);
        setTotalActiveTasks(activeTasks.length);
    };


    useEffect(() => {
        console.log("in workspace: useEffect: setTaskStats");
        console.log("in workspace: tasks.length:", tasks.length);
        setTotalTasks(tasks.length);
        setTotalActiveTasks(activeTasks.length);
        createTaskChartData();
    }, [tasks]);


    // console.log("tasks: " + JSON.stringify(tasks));
    // console.log("activeTasks: " + JSON.stringify(activeTasks));
    console.log("totalTasks: " + JSON.stringify(totalTasks));
    // console.log("totalActiveTasks: " + JSON.stringify(totalActiveTasks));
    console.log("taskChartData: " + JSON.stringify(taskChartData));
    console.log("habitStats: " + JSON.stringify(habitStats));

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Productivity Hub: Goals, Habits, and Tasks</title>
                <meta name="description" content="Organize your goals, track your habits, and manage your tasks in one powerful workspace. Stay focused, motivated, and on track with our productivity tools." />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'} border-end`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1">
                    <div className="row py-3">
                        <div className="col-auto my-auto">
                            <div href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn border border-dark rounded-3 p-2">
                                Side Menu
                            </div>
                        </div>
                        <div className="col d-flex align-items-center">
                            {/* <h3 className="mb-0">Welcome back, {user.username}!</h3> */}
                        </div>
                    </div>
                    <div className="page-header">
                        {authorized ?
                            <div className="d-flex">
                                <div className="container-fluid">
                                    <div className="row p-1">
                                        <GoalWidget handleAuthorized={handleAuthorized}
                                            goals={goals}
                                            updateGoalOrder={updateGoalOrder}
                                            totalGoals={totalGoals}
                                            toggleGoalStatus={toggleGoalStatus}
                                            updateGoalDetail={updateGoalDetail}
                                            updateGoalMeasure={updateGoalMeasure}
                                            updateGoalDeadline={updateGoalDeadline}
                                            removeGoal={removeGoal}
                                        />
                                        <GoalStatusWidget goalStats={goalStats} totalGoals={totalGoals} className="" />
                                        <HabitWidget handleAuthorized={handleAuthorized}
                                            habits={habits}
                                            updateHabitOrder={updateHabitOrder}
                                            totalHabits={totalHabits}
                                            removeHabit={removeHabit}
                                            toggleHabitComplete={toggleHabitComplete}
                                            toggleHabitFocus={toggleHabitFocus}
                                            toggleHabitFrog={toggleHabitFrog}
                                            updateHabitStart={updateHabitStart}
                                            updateHabitEnd={updateHabitEnd}
                                            updateHabitDetail={updateHabitDetail}
                                        />
                                        <HabitStatusWidget habitStats={habitStats} totalHabits={totalHabits} />
                                        <TaskWidget handleAuthorized={handleAuthorized}
                                            activeTasks={activeTasks}
                                            totalActiveTasks={totalActiveTasks}
                                            updateTaskOrder={updateTaskOrder}
                                            addTask={addTask}
                                            deleteTask={deleteTask}
                                            toggleTaskComplete={toggleTaskComplete}
                                            toggleTaskFocus={toggleTaskFocus}
                                            toggleTaskFrog={toggleTaskFrog}
                                        />
                                        <TaskStatusWidget
                                            taskChartData={taskChartData}
                                            totalTasks={totalTasks}
                                        />
                                    </div>
                                    <div className="row p-1">
                                        {/* <WorkWidget title="Last 7 Days" aspect={2 / 1} handleAuthorized={handleAuthorized} /> */}
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div className="row p-0 m-0">
                                    <div className="m-0 p-2 text-success text-center">
                                        <p className="m-0 p-0">
                                            You've been logged out due to inactivity.<br />
                                            Click <Link to="/login">here</Link> to login.
                                        </p>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >


    );
};

export default Workspace;